// formRenderer.js
/* global notificationUI */
import $ from "jquery";
import { renderLateFeeTable } from "./lateFeeHandler.js";
import { renderDateChangeFeeTable } from "./dateChangeFeeHandler.js";
import { validateForm } from "./validations";
import { renderStripeElements } from "./stripeLogic.js";
import { setupSearch, setupExistenceCheck, showTimePicker, isFieldVisible, updateVisibility, updateFeeTable, hideCustomerFields, showNewCustomerFields, showOldCustomerFields } from "./miscellaneous.js";
import { updateDateSelector, initDateSelector } from "./dateSelector.js";
import { showAmortizationSchedule } from "./amortizationSchedule.js";
import { renderTransactionFeeTable } from "./transactionFeeHandler.js";

export function renderForm(formConfig, formDataStore) {
	const form = $("<form id='dynamicForm'></form>");
	const tabContainer = $("#tabContainer");
	const mainContent = $("#mainContent");

	// Clear previous content
	tabContainer.empty().hide();
	mainContent.empty();

	const formData = formDataStore.getFormData();
	
	if (formConfig.pages.length > 1) {
		// Show tabs for multi-page forms
		tabContainer.show();
		formConfig.pages.forEach((page, index) => {
			const tabButton = $(`<button class='tab-button' data-page="${index}">${page.title}</button>`);
			tabContainer.append(tabButton);
		});

		// Tab switching logic
		tabContainer.on("click", ".tab-button", function () {
			const pageIndex = $(this).data("page");
			const currentPage = formConfig.pages[pageIndex];
			const formData = formDataStore.getFormData();
			if (currentPage.title === "Payment Methods") {
				console.log("Rendering Payment Methods page", formData);
				if (!formData.customer_email) { 
					$(".form-field[data-field-key='stripe_elements']").hide();
					notificationUI.sendNotification({
						type: "ERROR",
						title: "Please enter customer information to access Payment Methods.",
						save: false
					});
				}
				
			}
			switchToPage(pageIndex);
		});
	}

	formConfig.pages.forEach((page, index) => {
		const pageDiv = $("<div class='form-page'></div>").attr("data-page", index);
		if (!page.hideLabel) {
			pageDiv.append(`<h2>${page.title}</h2>`);
		}

		page.panels.forEach(panel => {
			const panelDiv = renderPanel(panel, formDataStore, formConfig);
			pageDiv.append(panelDiv);
		});
		let navDiv;
		if (page?.navigation?.previous || page?.navigation?.next) {
			navDiv = $("<div class='form-navigation'></div>");
		}
		if (page?.navigation?.previous && navDiv) {
			navDiv.append(renderButton(page.navigation.previous));
		}
		if (page?.navigation?.next && navDiv) {
			navDiv.append(renderButton(page.navigation.next));
		}
		if (page?.navigation?.submit && navDiv) {
			navDiv.append(renderButton(page.navigation.submit));
		}
		if (navDiv) {
			pageDiv.append(navDiv);
		}
		form.append(pageDiv);
	});
	mainContent.append(form);
	// Show first page by default
	$(".form-page").first().addClass("active");
	$(".tab-button").first().addClass("active");

	$(document).on("change", "#dynamicForm option, #dynamicForm checkbox, #dynamicForm input, #dynamicForm select, #dynamicForm textarea", function () {
		const name = $(this).attr("name");
		let value = $(this).val();
		const type = $(this).attr("type");

		const inputGroup = $(this).closest(".form-field");
		inputGroup.removeClass("invalid");
		inputGroup.find(".error-message").hide();

		console.log("Form change:", name, value, $(this));
		let formData = formDataStore.getFormData();

		if (name === "customer_type") {
			if (value === "new") {
				formData = formDataStore.updateFormData({
					customer_id: "",
					customer_email: "",
					customer_mobile: "",
					customer_first_name: "",
					customer_last_name: "",
					customer_type: "new",
					existing_customer_search: { value: "", isValid: false }
				});
				hideCustomerFields();
				showNewCustomerFields();
			} else if (value === "existing") {
				hideCustomerFields();
				formData = formDataStore.updateFormData({
					customer_type: "existing",
					customer_id: null,
					customer_email: null,
					customer_mobile: null,
					customer_first_name: null,
					customer_last_name: null,
					existing_customer_search: { value: "", isValid: false }
				});
			}
		} else if (name === "existing_customer_search") {
			if (formData?.existing_customer_search?.isValid) {
				console.log("SELECTED VALUE", value);
				value = formData.existing_customer_search;
				showOldCustomerFields(formData?.existing_customer_search.customer);
			} else if (!formData.existing_customer_search || !formData.existing_customer_search.isValid) {
				console.log("Typing and invalid", formData.existing_customer_search);
				hideCustomerFields();
				value = { value: value, isValid: false };
				formData = formDataStore.updateFormData({
					customer_id: null,
					customer_email: null,
					customer_mobile: null,
					customer_first_name: null,
					customer_last_name: null,
					existing_customer_search: value
				});
			}
		} else if (name === "customer_email") {
			if (value) {
				formData = formDataStore.updateFormData({ customer_email: value });
				$("#saved-payment-methods").empty();
				renderStripeElements(formDataStore);
			} else {
				formData = formDataStore.updateFormData({ customer_email: "" });
				$("#saved-payment-methods").empty();
				notificationUI.sendNotification({
					type: "ERROR",
					title: "Please enter a valid email address to save payment methods."
				});
			}
		}

		switch (type) {
		case "checkbox":
			let updatedCheckboxValue = formData[name] || [];
			if ($(this).is(":checked")) {
				if (!updatedCheckboxValue.includes(value)) {
					updatedCheckboxValue.push(value);
				}
			} else {
				updatedCheckboxValue = updatedCheckboxValue.filter(v => v !== value);
			}
			formData = formDataStore.updateFormData({ [name]: updatedCheckboxValue });
			break;
		case "radio":
			formData = formDataStore.updateFormData({ [name]: value });
			break;
		default:
			formData = formDataStore.updateFormData({ [name]: value });
		}

		if (name === "payment_methods") {
			if ($(this).is(":checked")) {
				if (!formData.payment_methods.includes(value)) {
					formData.payment_methods.push(value);
				}
			} else {
				formData.payment_methods = formData.payment_methods.filter(method => method !== value);
			}
		} else if ($(this).attr("type") === "checkbox") {
			formData[name] = formData[name] || [];
			if ($(this).is(":checked")) {
				if (!formData[name].includes(value)) {
					formData[name].push(value);
				}
			} else {
				formData[name] = formData[name].filter(v => v !== value);
			}
		} else if (["schedule_type", "static_day", "week_of_month", "day_of_week", "payment_time", "timezone"].includes(name)) {
			if (name === "schedule_type") {
				if (value === "static") {
					const dateOption = new Date().getDate() + 7;
					formData["static_day"] = formData["static_day"] || (dateOption > 28 ? 1 : dateOption);
					$("select[name=\"static_day\"]").val(formData["static_day"]);
					formData["week_of_month"] = "";
					formData["day_of_week"] = "";
				} else if (value === "dynamic") {
					formData["static_day"] = "";
					formData["week_of_month"] = formData["week_of_month"] || "first";
					formData["day_of_week"] = formData["day_of_week"] || "sunday";
				}
			}
			formData = formDataStore.updateFormData(formData);
			updateDateSelector(formDataStore);
		} else {
			formData[name] = value;
		}

		formData = formDataStore.updateFormData(formData);
		$(this).removeClass("invalid");
		$(this).siblings(".error-message").hide();
		updateFeeTable(name, value, $(this).closest("form"), formDataStore);
		updateVisibility(name, value, $(this).closest("form"), formConfig, formDataStore);
		$(document).trigger("formDataChanged");
	});
	const existingCustomerSearchElement = document.querySelector("#existing_customer_search");
	if (existingCustomerSearchElement) {
		existingCustomerSearchElement.addEventListener("input", (event) => {
			const input = event.target;
			const query = input.value;
			const formData = formDataStore.getFormData();
			if (!query) {
				formDataStore.updateFormData({
					existing_customer_search: { value: "", isValid: false },
					customer_id: null,
					customer_email: null,
					customer_mobile: null,
					customer_first_name: null,
					customer_last_name: null
				});
				hideCustomerFields();
			} else {
				formDataStore.updateFormData({
					existing_customer_search: { value: query, isValid: false },
					customer_id: null,
					customer_email: null,
					customer_mobile: null,
					customer_first_name: null,
					customer_last_name: null
				});
				hideCustomerFields();
			}
		});
	}

	$(document).on("click", ".btn-next, .btn-previous", function (e) {
		e.preventDefault();
		const currentPage = $(this).closest(".form-page");
		const currentIndex = currentPage.data("page");
		const nextIndex = $(this).hasClass("btn-next") ? currentIndex + 1 : currentIndex - 1;

		if ($(this).hasClass("btn-next") && !validateForm(form, currentIndex)) {
			return;
		}

		switchToPage(nextIndex);
	});
	$(document).on("click", function (event) {
		$(".suggestions-box").remove();
	});
	switchToPage(0);
	updateVisibility(null, null, form, formConfig, formDataStore);
	$(document).on("click", ".time-input", function (e) {
		e.stopPropagation();
		showTimePicker($(this));
	});
	$(document).on("click", "button[data-action='showAmortization']", function () {
		const formData = formDataStore.getFormData();
		showAmortizationSchedule(formData);
	});
	return {
		form: form,
		validateForm: validateForm
	};
}

function renderField(field, formDataStore, formConfig) {
	let formData = formDataStore.getFormData();
	const feeFields = formData.feeFields || {};
	const fieldDiv = $("<div class='form-field'></div>").attr("data-field-key", field.key);
	fieldDiv.attr("data-field-type", JSON.stringify(field));

	if (!(field.type == "hidden" || field.type == "button")) {
		const labelWrapper = $("<div class='label-wrapper'></div>");
		if (!field.hideLabel) {
			labelWrapper.append(`<label for="${field.key}">${field.label}</label>`);
		}
		if (field.tooltip) {
			labelWrapper.append(`<span class="tooltip-icon" data-tooltip="${field.tooltip}">?</span>`);
		}
		fieldDiv.append(labelWrapper);
	}

	let input;
	switch (field.type) {
	case "custom":
		if (field.customType === "html") {
			input = $(field.customOptions.htmlContent);
		} else if (field.customType === "fee_table") {
			feeFields[field.key] = field;
			switch (field.feeType) {
			case "late":
				input = renderLateFeeTable(field, formDataStore);
				break;
			case "transaction":
				input = renderTransactionFeeTable(field, formDataStore);
				break;
			case "dateChange":
				input = renderDateChangeFeeTable(field, formDataStore);
				break;
			default:
				console.warn(`Unsupported fee type: ${field.feeType}`);
				return null;
			}
		} else if (field.customType === "search") {
			input = $(`<input type="${field.customOptions.internal_type || "text"}" id="${field.key}" name="${field.key}">`);
			setupSearch(input, field.customOptions, formDataStore);
		} else if (field.customType === "check_existence") {
			input = $(`<input type="${field.customOptions.internal_type || "text"}" id="${field.key}" name="${field.key}">`);
			setupExistenceCheck(input, field.customOptions, formDataStore);
		} else if (field.customType === "amortization") {
			input = $(`<button type="button" id="${field.key}" class="custom-button">${field.label}</button>`);
			input.attr("data-action", field.customOptions.action);
		}
		break;
	case "hidden":
		input = $(`<input type="hidden" id="${field.key}" name="${field.key}">`);
		break;
	case "text":
	case "textarea":
	case "email":
	case "password":
		input = $(`<input type="${field.inputType || field.type}" id="${field.key}" name="${field.key}">`);
		break;
	case "number":
		const step = field.validate.integer ? "1" : (field.step || "any"); // Use '1' for integers and 'any' for floats
		const min = field.validate.min !== undefined ? field.validate.min : ""; // Optional: Set a default min value
		const max = field.validate.max !== undefined ? field.validate.max : ""; // Optional: Set a default max value
		input = $(`<input type="number" id="${field.key}" name="${field.key}" step="${step}" min="${min}" max="${max}">`);
		break;
	case "select":
		input = $(`<select id="${field.key}" name="${field.key}"></select>`);
		field.data.values.forEach(option => {
			const optionElement = $(`<option value="${option.value}">${option.label}</option>`);
			if (option.selected) {
				optionElement.prop("selected", true);
				formData[field.key] = option.value;
				if (field.key === "schedule_type") {
					// Reset related fields when schedule type changes
					if (option.value === "static") {
						const dateOption = new Date().getDate() + 7;
						formData["static_day"] = formData["static_day"] || (dateOption > 28 ? 1 : dateOption);
						$("select[name=\"static_day\"]").val(formData["static_day"]);
						formData["week_of_month"] = "";
						formData["day_of_week"] = "";
					} else if (option.value === "dynamic") {
						formData["static_day"] = "";
						formData["week_of_month"] = formData["week_of_month"] || "first";
						formData["day_of_week"] = formData["day_of_week"] || "sunday";
					}
					formData = formDataStore.updateFormData(formData);
					updateDateSelector(formDataStore);
				}
				formData = formDataStore.updateFormData(formData);
			}
			input.append(optionElement);
		});
		break;
	case "radio":
		input = $("<div class='radio-group'></div>");
		field.data.values.forEach(option => {
			const radioInput = $(`
				<label>
                            <input type="radio" name="${field.key}" value="${option.value}">
                            ${option.label}
                        </label>
                    `);
			if (option.selected) {
				radioInput.find("input").prop("checked", true);
				formData[field.key] = option.value;
				formData = formDataStore.updateFormData({ [field.key]: option.value });
			}
			input.append(radioInput);
		});
		break;
	case "checkbox":
		input = $("<div class='checkbox-group'></div>");
		field.data.values.forEach(option => {
			const checkboxInput = $(`
                        <label>
                            <input type="checkbox" name="${field.key}" value="${option.value}">
                            ${option.label}
                        </label>
                    `);
			if (option.selected) {
				checkboxInput.find("input").prop("checked", true);
				if (!formData[field.key]) { formData[field.key] = []; }
				formData[field.key].push(option.value);
				formData = formDataStore.updateFormData(formData);
			}
			input.append(checkboxInput);
		});
		break;
	case "time":
		input = $(`<input type="text" id="${field.key}" name="${field.key}" class="time-input" readonly>`);
		if (field.defaultValue) {
			input.val(field.defaultValue);
		}
		break;
	case "date":
		if (field.customDateSelector) {
			input = $(`<select id="${field.key}" name="${field.key}" class="date-select"></select>`);
			initDateSelector(input, formDataStore, field.dateOptions);
		} else {
			input = $(`<input type="date" id="${field.key}" name="${field.key}">`);
		}
		break;
	case "button":
		input = $(`<button type="${field.action}" id="${field.key}">${field.label}</button>`);
		if (field.customOptions && field.customOptions.action === "showAmortization") {
			input.attr("data-action", "showAmortization");
		}
		break;
	default:
		console.warn(`Unsupported field type: ${field.type}`);
		return null;
	}
	if (!input) {
		console.error("No input element found for field:", field);
	}

	if (field.placeholder) {
		input.attr("placeholder", field.placeholder);
	}

	if (field.validate && field.validate.required) {
		input.prop("required", true);
	}

	if (field.type != "hidden" && isFieldVisible(field, formConfig, formDataStore)) {
		input.prop("required", true);
	}
	fieldDiv.append(input);

	if (field.errorMessage) {
		fieldDiv.append(`<div class="error-message" style="display:none;">${field.errorMessage}</div>`);
	}
	formData = formDataStore.updateFormData(formData);
	return fieldDiv;
}

function renderPanel(panel, formDataStore, formConfig) {
	console.log("Rendering panel:", panel.title);
	const panelDiv = $("<div class='form-panel'></div>");
	if (!panel.hideLabel) {
		panelDiv.append(`<h3>${panel.title}</h3>`);
	}

	panel.components.forEach(field => {
		const fieldDiv = renderField(field, formDataStore, formConfig);
		if (fieldDiv) {
			panelDiv.append(fieldDiv);
		}
	});

	return panelDiv;
}

function renderButton(button) {
	return $(`<button type="${button.action}" class="btn btn-${button.action}" data-key="${button.key}">${button.label}</button>`);
}

export function switchToPage(pageIndex) {
	$(".form-page").removeClass("active").hide();
	$(".form-page").eq(pageIndex).addClass("active").show();
	$(".tab-button").removeClass("active");
	$(".tab-button").eq(pageIndex).addClass("active");
}