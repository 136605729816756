import $ from "jquery";
export function renderDateChangeFeeTable(field, formDataStore) {
	const formData = formDataStore.getFormData();
	const wrapper = $("<div class='fee-table date-change-fee-table' data-field-key='" + field.key + "'></div>");
	let rows = formData[field.key] || [{ from: 0, to: 99999, static: 0, dynamic: 0, max: 0, min: 0 }];
	const chargeFeeCurrent = formData.date_change_fee_policy != "all_free";
	const feeStructure = formData.fee_structure_date_change || "static";
	const feeBasedOn = formData.fee_based_on || "days_moved";
	function createTable() {
		const table = $("<table class='fee-table-grid'></table>");
		const headerRow = $("<tr></tr>");
		const fromToLabels = feeBasedOn === "days_moved" ? ["From Days Moved", "To Days Moved"] : ["Days Before Due Date", "To Days Before"];
		[fromToLabels[0], fromToLabels[1], "Static Part ($)", "Dynamic Part (%)", "Minimum ($)", "Maximum ($)", "Action"].forEach(header => {
			headerRow.append(`<th>${header}</th>`);
		});
		table.append(headerRow);
		return table;
	}

	function renderRow(row, index) {
		const tr = $("<tr></tr>");
		const isLastRow = index === rows.length - 1;

		["from", "to", "static", "dynamic", "min", "max"].forEach(key => {
			const input = $(`<input type="number" name="${field.key}[${index}][${key}]" value="${row[key]}" step="0.01">`);

			if (key === "from") {
				input.prop("readonly", true);
				input.addClass("readonly-input");
			}
			input.on("change", function () {
				const newValue = parseFloat($(this).val()) || 0;
				row[key] = newValue;
				$(this).val(newValue);
				validateAndUpdateRows(index, key);
			});

			tr.append($("<td></td>").append(input));
		});

		const actionTd = $("<td></td>");
		if (!isLastRow) {
			const removeButton = $("<button type='button' class='remove-row'>Remove</button>");
			removeButton.on("click", function () {
				rows.splice(index, 1);
				validateAndUpdateRows();
			});
			actionTd.append(removeButton);
		}
		tr.append(actionTd);

		return tr;
	}

	function validateAndUpdateRows(changedIndex, changedKey) {
		if (!chargeFeeCurrent) {
			rows = [{ from: 0, to: 99999, static: 0, dynamic: 0, min: 0, max: 0 }];
		} else {
			if (feeStructure === "static") {
				rows = [{ from: 0, to: 99999, static: rows[0].static, dynamic: 0, min: rows[0].min, max: rows[0].max }];
			} else if (changedKey === "to") {
				const newRow = { from: rows[changedIndex].to, to: rows[changedIndex + 1]?.from ?? 99999, static: rows[changedIndex]?.static ?? 0, dynamic: rows[changedIndex]?.dynamic ?? 0, max: rows[changedIndex]?.max ?? 0, min: rows[changedIndex]?.min ?? 0 };
				rows.splice(changedIndex + 1, 0, newRow);
			}
			if (rows[0].from != 0) {
				rows[0].from = 0;
			}
			for (let i = 0; i < rows.length; i++) {
				if (i > 0) {
					rows[i].from = rows[i - 1].to;
				}
				rows[i].to = Math.max(rows[i].from + 1, parseFloat(rows[i].to) || 0);
				rows[i].static = Math.max(0, parseFloat(rows[i].static) || 0);
				rows[i].dynamic = Math.min(100, Math.max(0, parseFloat(rows[i].dynamic) || 0));
				rows[i].min = Math.max(0, Math.min(parseFloat(rows[i].min) || 0, parseFloat(rows[i].max) || 0));
				rows[i].max = Math.max(parseFloat(rows[i].static) || 0, parseFloat(rows[i].min) || 0, parseFloat(rows[i].max) || 0);

				if (i === rows.length - 1) {
					rows[i].to = 99999;
				}
			}
		}

		updateTable();
	}

	function updateTable() {
		const table = createTable();
		wrapper.empty().append(table);
		rows.forEach((row, index) => {
			table.append(renderRow(row, index));
		});
		if (feeStructure === "tiered") {
			const addButton = $("<button type='button' class='add-row'>Add New Row</button>");
			addButton.on("click", function () {
				const lastRow = rows[rows.length - 1];
				rows.push({ from: lastRow.to, to: 99999, static: 0, dynamic: 0, min: 0, max: 0 });
				validateAndUpdateRows();
			});
			wrapper.append(addButton);
		}
		formData[field.key] = rows;
	}

	validateAndUpdateRows();
	return wrapper;
}