export function validateForm(form, currentIndex) {
	let isValid = true;
	let firstInvalidField = null;

	form.find(".form-page").each(function (pageIndex) {
		if (pageIndex != currentIndex) {
			return;
		}
		const page = $(this);
		page.find(":input").each(function () {
			const field = $(this);
			const fieldName = field.attr("name");
			if (field.is(":radio") || field.is(":checkbox")) {
				const inputGroup = form.find(`input[name="${fieldName}"]`);
				const formField = inputGroup.closest(".form-field");
				if (inputGroup.filter(":checked").length === 0 && inputGroup.first().prop("required")) {
					isValid = false;
					formField.addClass("invalid");
					formField.find(".error-message").show();
					if (!firstInvalidField) {
						firstInvalidField = { field: formField, pageIndex };
					}
				} else {
					formField.removeClass("invalid");
					formField.find(".error-message").hide();
				}
			} else if (field.prop("required") && !field.val().trim() && !field.is(":button")) {
				isValid = false;
				field.addClass("invalid");
				if (!firstInvalidField) {
					firstInvalidField = { field, pageIndex };
				}
			} else {
				field.removeClass("invalid");
			}
		});
	});

	if (!isValid && firstInvalidField) {
		console.log("Invalid form detected", firstInvalidField);
		switchToPage(firstInvalidField.pageIndex);
		setTimeout(() => {
			firstInvalidField.field[0].scrollIntoView({ behavior: "smooth", block: "center" });
			firstInvalidField.field.find(":input").first().focus();
		}, 100);
	}

	return isValid;
}

export function switchToPage(pageIndex) {
	$(".form-page").removeClass("active").hide();
	$(".form-page").eq(pageIndex).addClass("active").show();
	$(".tab-button").removeClass("active");
	$(".tab-button").eq(pageIndex).addClass("active");
}