export function calculateNextValidDate(currentDate, formData, previousDate) {
	const now = previousDate ? new Date(previousDate) : new Date();
	let date = new Date(currentDate || now);

	// Check if payment_time is available, if not use current time
	if (formData.payment_time) {
		const [hours, minutes] = formData.payment_time.split(":").map(Number);
		date.setHours(hours, minutes, 0, 0);
	} else {
		date.setHours(8, 0, 0, 0); // Default to midnight if no time is set
	}
	if (formData.schedule_type === "static" && formData.static_day) {
		const day = parseInt(formData.static_day);
		date.setDate(day);
		while (date <= now) {
			date.setMonth(date.getMonth() + 1);
		}
	} else if (formData.schedule_type === "dynamic" && formData.week_of_month && formData.day_of_week) {
		date = getNextDynamicDate(date, formData.week_of_month, formData.day_of_week);
		while (date <= now) {
			date = getNextDynamicDate(date, formData.week_of_month, formData.day_of_week);
		}
	} else {
		// If schedule type is not set or incomplete, use the next day
		date = new Date(date.getTime() + 24 * 60 * 60 * 1000);
	}
	return date;
}

export function getNextDynamicDate(startDate, weekOfMonth, dayOfWeek) {
	const date = new Date(startDate);
	const targetDay = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"].indexOf(dayOfWeek);

	// Helper function to adjust the date based on weekOfMonth
	const adjustBasedOnWeekOfMonth = (d, weekOfMonth) => {
		switch (weekOfMonth) {
		case "first":
			// Already on the first occurrence
			break;
		case "second":
			d.setDate(d.getDate() + 7);
			break;
		case "third":
			d.setDate(d.getDate() + 14);
			break;
		case "fourth":
			d.setDate(d.getDate() + 21);
			break;
		case "last":
			// Move to the last occurrence of the target day in the month
			const lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
			while (d.getDay() !== targetDay || d.getDate() + 7 <= lastDayOfMonth.getDate()) {
				d.setDate(d.getDate() + 7);
			}
			break;
		case "second_last":
			// Move to the second last occurrence of the target day in the month
			const secondLastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
			const lastOccurrence = new Date(d);
			while (lastOccurrence.getDay() !== targetDay || lastOccurrence.getDate() + 7 <= secondLastDayOfMonth.getDate()) {
				lastOccurrence.setDate(lastOccurrence.getDate() + 7);
			}
			if (lastOccurrence.getDate() > 7) {
				lastOccurrence.setDate(lastOccurrence.getDate() - 7);
			}
			d.setTime(lastOccurrence.getTime());
			break;
		default:
			throw new Error("Invalid weekOfMonth value");
		}
	};

	// Check if the startDate meets the criteria
	const checkStartDate = (d, weekOfMonth) => {
		const day = d.getDay();
		const date = d.getDate();
		const firstDayOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
		const firstOccurrence = new Date(firstDayOfMonth);

		while (firstOccurrence.getDay() !== targetDay) {
			firstOccurrence.setDate(firstOccurrence.getDate() + 1);
		}

		switch (weekOfMonth) {
		case "first":
			return day === targetDay && date === firstOccurrence.getDate();
		case "second":
			return day === targetDay && date === firstOccurrence.getDate() + 7;
		case "third":
			return day === targetDay && date === firstOccurrence.getDate() + 14;
		case "fourth":
			return day === targetDay && date === firstOccurrence.getDate() + 21;
		case "last":
			const lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
			const lastOccurrence = new Date(firstOccurrence);
			while (lastOccurrence.getDate() + 7 <= lastDayOfMonth.getDate()) {
				lastOccurrence.setDate(lastOccurrence.getDate() + 7);
			}
			return day === targetDay && date === lastOccurrence.getDate();
		case "second_last":
			const secondLastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 0);
			const secondLastOccurrence = new Date(firstOccurrence);
			while (secondLastOccurrence.getDate() + 7 <= secondLastDayOfMonth.getDate()) {
				secondLastOccurrence.setDate(secondLastOccurrence.getDate() + 7);
			}
			if (secondLastOccurrence.getDate() > 7) {
				secondLastOccurrence.setDate(secondLastOccurrence.getDate() - 7);
			}
			return day === targetDay && date === secondLastOccurrence.getDate();
		default:
			throw new Error("Invalid weekOfMonth value");
		}
	};

	// If the startDate meets the criteria, return it
	if (checkStartDate(date, weekOfMonth)) {
		return date;
	}

	// Otherwise, move to the first day of the current month
	date.setDate(1);

	// Find the first occurrence of the target day in the current month
	while (date.getDay() !== targetDay) {
		date.setDate(date.getDate() + 1);
	}

	// Adjust based on weekOfMonth
	adjustBasedOnWeekOfMonth(date, weekOfMonth);

	// If the calculated date is in the past, move to the first day of the next month and repeat
	if (date <= new Date(startDate)) {
		date.setMonth(date.getMonth() + 1);
		date.setDate(1);
		while (date.getDay() !== targetDay) {
			date.setDate(date.getDate() + 1);
		}
		adjustBasedOnWeekOfMonth(date, weekOfMonth);
	}

	return date;
}

export function updateDateSelector(formDataStore) {
	const formData = formDataStore.getFormData();
	console.log("Updating date selector", formData);
	const startDateSelect = $("select[name='start_date']");
	startDateSelect.empty();
	let currentDate = new Date();
	let previousDate;
	for (let i = 0; i < 10; i++) {
		const nextDate = calculateNextValidDate(currentDate, formData, previousDate);
		previousDate = nextDate;
		const option = $("<option></option>")
			.val(nextDate.toISOString().split("T")[0])
			.text(formatDateOption(nextDate, formData));
		startDateSelect.append(option);
		if (formData.schedule_type === "static") {
			currentDate = new Date(currentDate).setMonth(new Date(currentDate).getMonth() + 1);
		} else {
			currentDate = new Date(nextDate.getTime() + 24 * 60 * 60 * 1000); // Move to next day
		}

	}
	formData.start_date = startDateSelect.val();
	// Update static_day to match start_date if schedule_type is static
	if (formData.schedule_type === "static") {
		const staticDay = new Date(formData.start_date).getDate().toString();
		formData["static_day"] = staticDay;
		$("select[name='static_day']").val(staticDay);
	}
	formDataStore.updateFormData(formData);

}


function capitalizeFirstLetters(str) {
	return str.replace(/(^|\s|_)[a-z]/g, match => match.toUpperCase()).replace(/_/g, " ");
}

export function formatDateOption(date, formData) {
	const dateString = date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
	if (formData.schedule_type === "static") {
		const staticDay = formData.static_day ? `${formData.static_day}th of Month` : "";
		return `${dateString} (${staticDay})`;
	}
	const weekOfMonth = formData.week_of_month ? `${capitalizeFirstLetters(formData.week_of_month)} ` : "";
	const dayOfWeek = formData.day_of_week ? `${capitalizeFirstLetters(formData.day_of_week)}` : "";
	return `${dateString} (${weekOfMonth}${dayOfWeek})`;
}


function updateDateOptions(select, formDataStore) {
	const formData = formDataStore.getFormData();
	select.empty();
	let currentDate = new Date();
	let previousDate;
	for (let i = 0; i < 10; i++) {
		const nextDate = calculateNextValidDate(currentDate, formData, previousDate);
		previousDate = nextDate;
		const option = $("<option></option>")
			.val(nextDate.toISOString().split("T")[0])
			.text(formatDateOption(nextDate, formData));
		select.append(option);
		currentDate = new Date(nextDate.getTime() + 24 * 60 * 60 * 1000); // Move to next day
	}
}
export function initDateSelector(select, formDataStore, dateOptions) {
	const formData = formDataStore.getFormData();
	console.log("Initializing date selector:", dateOptions, formData);

	updateDateOptions(select, formDataStore);
	select.on("change", function () {
		$(document).trigger("formDataChanged");
	});

	// Update options when relevant form fields change
	$(document).on("change", "#schedule_type, #static_day, #week_of_month, #day_of_week, #payment_time, #timezone", function () {
		updateDateOptions(select, formDataStore);
	});
}