// FormDataStore.js
class FormDataStore {
	constructor(initialData = { payment_methods: [], feeFields: {} }) {
		console.log("Initializing FormDataStore with initial data:", initialData);
		this.formData = initialData;
		this.listeners = [];
	}

	getFormData() {
		return this.formData;
	}

	async updateFormData(newData) {
		this.formData = { ...this.formData, ...newData };
		this.notifyListeners();
		return this.formData; 
	}

	subscribeToFormData(listener) {
		this.listeners.push(listener);
	}

	unsubscribeFromFormData(listener) {
		const index = this.listeners.indexOf(listener);
		if (index !== -1) {
			this.listeners.splice(index, 1);
		}
	}

	notifyListeners() {
		this.listeners.forEach(listener => listener(this.formData));
	}
}

export default FormDataStore;
