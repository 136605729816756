// notifications.js
import { apiClient } from "./apiClient";
export class NotificationUI {
	constructor() {
		this.container = document.getElementById("notification-container");
		this.center = document.getElementById("notification-center");
		this.list = document.getElementById("notification-list");
		this.badge = document.getElementById("notification-badge");
		this.settings = document.getElementById("notification-settings");
		this.toggleButton = document.getElementById("notification-toggle");
		this.badgeCount = 0;
		this.notifications = [];
		this.isOpen = false;
		this.isLoggedIn = !!localStorage.getItem("authToken");
		if (this.allElementsExist()) {
			this.setup();
		} else {
			console.warn("Some notification elements are missing. Notification system may not function properly.");
		}
	}

	allElementsExist() {
		return this.container && this.center && this.list && this.badge &&
			this.settings && this.toggleButton;
	}

	async fetchAndApplySettings() {
		try {
			const response = await apiClient("/api/business/notifications/settings");
			//console.log("Fetched and applied settings:", response.settings);
			if (response) {
				this.applySettings(response.settings);
			}
		} catch (error) {
			console.error("Error fetching settings:", error);
		}
	}
	setup() {
		if (this.toggleButton) {
			//console.log("THis also");
			this.toggleButton.addEventListener("click", (e) => {
				e.stopPropagation();
				this.toggleNotificationCenter();
			});
		}

		const closeButton = document.getElementById("notification-close");
		if (closeButton) {
			closeButton.addEventListener("click", (e) => {
				e.stopPropagation();
				this.closeNotificationCenter();
			});
		}

		const settingsToggle = document.getElementById("notification-settings-toggle");
		if (settingsToggle) {
			settingsToggle.addEventListener("click", () => this.toggleSettings());
		}

		const saveSettings = document.getElementById("save-settings");
		if (saveSettings) {
			saveSettings.addEventListener("click", () => this.saveSettings());
		}

		const cancelSettings = document.getElementById("cancel-settings");
		if (cancelSettings) {
			cancelSettings.addEventListener("click", () => this.toggleSettings());
		}

		this.updateNotificationVisibility();
	}

	updateNotificationVisibility() {
		//console.log("Updating visibility, isLoggedIn:", this.isLoggedIn);
		if (this.isLoggedIn) {
			this.toggleButton.style.display = "flex";
			this.fetchAndApplySettings();
		} else {
			this.toggleButton.style.display = "none";
			this.center.classList.remove("open");
			this.notifications = [];
			this.renderNotifications();
		}
		this.updateBadge(this.badgeCount);
	}

	async refreshNotifications() {
		if (this.isLoggedIn && !this.fetchingNotifications) {
			await this.fetchNotifications();
		}
	}

	async setLoggedIn(status) {
		//console.log("Setting logged in status:", status);
		if (this.isLoggedIn !== status) {
			this.isLoggedIn = status;
			this.updateNotificationVisibility();
			if (status) {
				await this.refreshNotifications();
			}
		}
	}

	toggleNotificationCenter() {
		this.isOpen = !this.isOpen;
		//console.log("Toggling notification center. isOpen:", this.isOpen);
		if (this.isOpen) {
			this.openNotificationCenter();
		} else {
			this.closeNotificationCenter();
		}
	}

	openNotificationCenter() {
		this.isOpen = true;
		//console.log("Opening notification center");
		this.center.classList.add("open");
		//console.log("Notification center classes:", this.center.className);
		this.fetchNotifications();
	}

	closeNotificationCenter() {
		this.isOpen = false;
		//console.log("Closing notification center");
		this.center.classList.remove("open");
		//console.log("Notification center classes:", this.center.className);
	}

	toggleSettings() {
		this.settings.classList.toggle("open");
	}

	async fetchNotifications() {
		if (!this.isLoggedIn || this.fetchingNotifications) {
			//console.log("Not fetching notifications: User not logged in or fetch already in progress");
			return;
		}
		try {
			this.fetchingNotifications = true;
			//console.log("Fetching notifications...");
			const response = await apiClient("/api/business/notifications");
			//console.log("Fetched notifications response:", response);
			this.notifications = response.notifications || [];
			this.renderNotifications();
		} catch (error) {
			console.error("Error fetching notifications:", error);
		} finally {
			this.fetchingNotifications = false;
		}
	}

	renderNotifications() {
		//console.log("Rendering notifications:", this.notifications);
		this.list.innerHTML = "";
		this.updateBadge(this.notifications.length);
		if (this.notifications.length === 0) {
			const emptyMessage = document.createElement("div");
			emptyMessage.className = "notification-empty";
			emptyMessage.textContent = "No notifications to display.";
			this.list.appendChild(emptyMessage);
		} else {
			this.notifications.forEach(notification => {
				const element = this.createNotificationElement(notification);
				this.list.appendChild(element);
			});
		}
	}

	createNotificationElement(notification) {
		const element = document.createElement("div");
		element.className = `notification notification-${notification.type.toLowerCase()}`;
		element.innerHTML = `
            <div class="notification-title">${notification.title}</div>
            <div class="notification-message">${notification?.message ?? ""}</div>
            <div class="notification-actions">
                <button class="notification-hide">Hide</button>
                <button class="notification-snooze">Snooze</button>
                <button class="notification-mute">Mute</button>
            </div>
        `;

		element.querySelector(".notification-hide").addEventListener("click", () => this.hideNotification(notification._id));
		element.querySelector(".notification-snooze").addEventListener("click", () => this.snoozeNotification(notification._id));
		element.querySelector(".notification-mute").addEventListener("click", () => this.muteNotification(notification._id));

		return element;
	}

	async hideNotification(id) {
		try {
			await apiClient(`/api/business/notifications/${id}`, {
				method: "PUT",
				body: JSON.stringify({ action: "read" })
			});
			this.notifications = this.notifications.filter(n => n._id !== id);
			this.renderNotifications();
		} catch (error) {
			console.error("Error hiding notification:", error);
		}
	}

	async snoozeNotification(id) {
		const duration = prompt("Snooze for how many minutes?", "60");
		if (duration) {
			try {
				await apiClient(`/api/business/notifications/${id}`, {
					method: "PUT",
					body: JSON.stringify({ action: "snooze", duration: parseInt(duration) * 60000 })
				});
				this.fetchNotifications();
			} catch (error) {
				console.error("Error snoozing notification:", error);
			}
		}
	}

	async muteNotification(id) {
		try {
			await apiClient(`/api/business/notifications/${id}`, {
				method: "PUT",
				body: JSON.stringify({ action: "mute" })
			});
			this.fetchNotifications();
		} catch (error) {
			console.error("Error muting notification:", error);
		}
	}

	updateBadge(count) {
		if (count !== undefined) {
			this.badgeCount = count;
		}
		this.badge.textContent = this.badgeCount;
		if (this.badgeCount > 99) {
			this.badge.textContent = "99+";
		}
		this.badge.style.display = this.badgeCount > 0 ? "flex" : "none";
	}

	async markAllAsRead() {
		try {
			await apiClient("/api/business/notifications/mark-all-read", { method: "PUT" });
			this.notifications.forEach(function (n) {
				n.isRead = true;
			});
			this.renderNotifications();
		} catch (error) {
			console.error("Error marking all as read:", error);
		}
	}

	saveSettings() {
		const position = document.getElementById("notification-position").value;
		const color = document.getElementById("notification-color").value;
		this.updateSettings({ position, color });
	}

	async updateSettings(settings) {
		try {
			await apiClient("/api/business/notifications/settings", {
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(settings)
			});
			this.applySettings(settings);
			this.toggleSettings();
		} catch (error) {
			console.error("Error updating settings:", error);
		}
	}

	applySettings(settings) {
		//console.log("Applying settings:", settings);

		// Remove existing position classes
		this.container.classList.remove("top-right", "top-left", "bottom-right", "bottom-left");

		// Add new position class
		this.container.classList.add(settings.position);

		// Update color
		this.container.style.setProperty("--notification-color", settings.color);
	}


	async sendNotification(notificationData) {
		if (!this.isLoggedIn) { return; }
		if (!this.allElementsExist()) {
			console.warn("Notification system is not properly initialized. Cannot send notification.");
			return;
		}
		try {
			const { save = true, showToast = true, ...data } = notificationData;

			if (save) {
				const response = await apiClient("/api/business/notifications", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify(data)
				});
				const newNotification = response.notification;
				this.notifications.unshift(newNotification);
				this.renderNotifications();
			}

			if (showToast) {
				this.showToast(data);
			}
		} catch (error) {
			console.error("Error sending notification:", error);
		}
	}

	showToast(notification) {
		const toast = document.createElement("div");
		toast.className = `notification-toast notification-${notification.type.toLowerCase()}`;
		toast.innerHTML = `
            <div class="notification-title">${notification.title}</div>
            <div class="notification-message">${notification?.message ?? ""}</div>
        `;
		this.container.appendChild(toast);
		setTimeout(() => {
			toast.remove();
		}, notification.duration || 5000);
	}

	async removeAllAlertsAndErrors() {
		try {
			await apiClient("/api/business/notifications/remove-alerts-errors", { method: "DELETE" });
			this.notifications = this.notifications.filter(n => !["ERROR", "WARNING"].includes(n.type));
			this.renderNotifications();
		} catch (error) {
			console.error("Error removing alerts and errors:", error);
		}
	}

	handleApiResponse(response) {
		if (response.notification_badge_count !== undefined) {
			this.updateBadge(response.notification_badge_count);
		}
	}

	isInitialized() {
		return this.allElementsExist();
	}
}