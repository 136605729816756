//stripeLogic.js
/* global Sortable */
/* global notificationUI */
import { loadStripe } from "@stripe/stripe-js";
import { apiClient } from "./apiClient";
const stripePromise = loadStripe("pk_test_51PYXaw2K8BerXPF2YhCnVDY2I3J9uCypNl1UYFjcB2JjnNpGpfb9WpLmTrXZnRvJXmJR9LYqTITOhgtitf3qOyWL00FyF6zDOV");

export async function renderStripeElements(formDataStore) {
	//console.log("Rendering Stripe elements");
	const stripe = await stripePromise;
	let formData = formDataStore.getFormData();
	const businessInfo = JSON.parse(localStorage.getItem("business_info"));
	const stripeAccountId = businessInfo?.stripe_account_id;

	if (!stripeAccountId) {
		console.error("Stripe account ID not found in local storage");
		alert("Stripe account ID not found. Please ensure you are logged in and have a Stripe account connected.");
		return;
	}

	let stripeCustomerId = formData["stripe_customer_id"];
	const customerEmail = formData["customer_email"];
	if (!stripeCustomerId) { 
		stripeCustomerId = await getStripeCustomerId(customerEmail);
		formData["stripe_customer_id"] = stripeCustomerId;
		formData = formDataStore.updateFormData(formData);
	}
	if (!stripeCustomerId) { 
		throw new Error("Failed to retrieve Stripe customer ID");
	}

	document.getElementById("add-payment-method").addEventListener("click", async () => {
		$("#stripe-elements-container").show();
		$("#payment-element").empty();
		$(".add-payment-method-container").hide();
		await mountStripeElements(stripe, stripeAccountId, customerEmail, stripeCustomerId, formDataStore);
	});
	
	if (stripeCustomerId) {
		await fetchAndRenderSavedPaymentMethods(stripeCustomerId, customerEmail, formDataStore);
	} else {
		console.error("Customer ID not available. Unable to fetch saved payment methods.");
	}
}
async function attachPaymentMethodToCustomer(paymentMethodId, customerId) {
	const token = localStorage.getItem("authToken");
	const API_BASE_URL = "/api/business/auth";
	await apiClient(`${API_BASE_URL}/stripe/attach-payment-method`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Authorization": token
		},
		body: JSON.stringify({
			paymentMethodId,
			customerId
		})
	});
}
async function fetchAndRenderSavedPaymentMethods(stripeCustomerId, customerEmail, formDataStore) {
	const token = localStorage.getItem("authToken");
	const API_BASE_URL = "/api/business/auth";
	let formData = formDataStore.getFormData();
	try {
		const paymentMethods = await apiClient(`${API_BASE_URL}/stripe/list-payment-methods?stripeCustomerId=${stripeCustomerId}&customerEmail=${customerEmail}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": token
			}
		});
		console.log("Payment methods:", paymentMethods);
		formData.paymentMethods = paymentMethods;
		formData = formDataStore.updateFormData(formData);
		if (!Array.isArray(paymentMethods) || paymentMethods.length === 0) {
			$("#saved-payment-methods").html("<p>No saved payment methods found.</p>");
			$("#stripe-elements-container").show();
			$(".add-payment-method-container").hide();
			return;
		}

		const tableHtml = `
            <table id="payment-methods-table" class="sortable">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Details</th>
                        <th>Expiry</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody id="payment-methods-tbody">
                    ${paymentMethods.map(pm => `
                        <tr data-id="${pm.id}">
                            <td>${pm.type.split("_").map(word => word.length < 3 ? word.toUpperCase() : word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}</td>
                            <td>${getPaymentMethodDetails(pm)}</td>
                            <td>${getPaymentMethodExpiry(pm)}</td>
                            <td><button class="remove-payment-method-btn" data-id="${pm.id}">Remove</button></td>
                        </tr>
                    `).join("")}
                </tbody>
            </table>
        `;

		$("#saved-payment-methods").html(tableHtml);
		$("#stripe-elements-container").hide();
		$(".add-payment-method-container").show();
		initializeSortable(formDataStore);
		addRemovePaymentMethodListeners(stripeCustomerId, formDataStore);
		updatePrimaryPaymentMethod(formDataStore);
	} catch (error) {
		console.error("Error fetching payment methods:", error);
		$("#saved-payment-methods").html(`<p>Error loading payment methods: ${error.message}</p>`);
		$("#stripe-elements-container").show();
		$(".add-payment-method-container").hide();
	}
}


function initializeSortable(formDataStore) {
	const tbody = document.getElementById("payment-methods-tbody");
	if (tbody) {
		Sortable.create(tbody, {
			animation: 150,
			onEnd: function (evt) {
				updatePrimaryPaymentMethod(formDataStore);
				updatePaymentMethodsOrder(formDataStore);
			}
		});
	}
}
function updatePaymentMethodsOrder(formDataStore) {
	let formData = formDataStore.getFormData();
	const tbody = document.getElementById("payment-methods-tbody");
	const rows = Array.from(tbody.getElementsByTagName("tr"));
	const newOrder = rows.map(row => {
		const id = row.getAttribute("data-id");
		return formData.paymentMethods.find(pm => pm.id === id);
	});
	formData.paymentMethods = newOrder;
	formData = formDataStore.updateFormData(formData);
}

function updatePrimaryPaymentMethod() {
	const tbody = document.getElementById("payment-methods-tbody");
	const rows = tbody.getElementsByTagName("tr");
	for (let i = 0; i < rows.length; i++) {
		if (i === 0) {
			rows[i].classList.add("primary-payment-method");
			// Add a visual indicator for the primary payment method
			if (!rows[i].querySelector(".primary-indicator")) {
				const indicator = document.createElement("span");
				indicator.className = "primary-indicator";
				indicator.textContent = " (Primary)";
				rows[i].querySelector("td").appendChild(indicator);
			}
		} else {
			rows[i].classList.remove("primary-payment-method");
			const indicator = rows[i].querySelector(".primary-indicator");
			if (indicator) {
				indicator.remove();
			}
		}
	}
}
function getPaymentMethodDetails(paymentMethod) {
	switch (paymentMethod.type) {
	case "card":
		return `${paymentMethod.card.brand} **** ${paymentMethod.card.last4}`;
	case "us_bank_account":
		return `${paymentMethod.us_bank_account.bank_name} **** ${paymentMethod.us_bank_account.last4}`;
	default:
		return "N/A";
	}
}

function getPaymentMethodExpiry(paymentMethod) {
	if (paymentMethod.type === "card") {
		return `${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`;
	}
	return "N/A";
}

function addRemovePaymentMethodListeners(customerId, formDataStore) {
	$(document).on("click", ".remove-payment-method-btn", async function () {
		const paymentMethodId = $(this).data("id");
		console.log("Removing payment method:", paymentMethodId);
		await removePaymentMethod(paymentMethodId, customerId, formDataStore);
	});
}

async function removePaymentMethod(paymentMethodId, customerId, formDataStore) {
	let formData = formDataStore.getFormData();
	const API_BASE_URL = "/api/business/auth";
	try {
		const response = await apiClient(`${API_BASE_URL}/stripe/remove-payment-method`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${localStorage.getItem("authToken")}`
			},
			body: JSON.stringify({ paymentMethodId, customerId })
		});

		// Remove the payment method row from the table
		const row = document.querySelector(`tr[data-id="${paymentMethodId}"]`);
		if (row) {
			row.remove();
		}
		// Update the formData.paymentMethods array
		formData.paymentMethods = formData.paymentMethods.filter(pm => pm.id !== paymentMethodId);
		formData = formDataStore.updateFormData(formData);
		// Update primary payment method if needed
		updatePrimaryPaymentMethod();
	} catch (error) {
		console.error("Error removing payment method:", error);
	}
}

async function getStripeCustomerId(customerEmail) {
	const token = localStorage.getItem("authToken");
	const API_BASE_URL = "/api/business/auth";
	try {
		const response = await apiClient(`${API_BASE_URL}/stripe/get-customer-id?email=${customerEmail}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": token
			}
		});
		console.log("response:", response);
		return response.stripe_customer_id;
	} catch (error) {
		console.error("Error fetching payment methods:", error);
		return null;
	}
}

async function createSetupIntent(stripeAccountId, customerEmail) {
	const token = localStorage.getItem("authToken");
	const API_BASE_URL = "/api/business/auth";
	const response = await apiClient(`${API_BASE_URL}/stripe/create-setup-intent`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Authorization": token
		},
		body: JSON.stringify({
			account: stripeAccountId,
			email: customerEmail
		})
	});
	return response;
}

async function mountStripeElements(stripe, stripeAccountId, customerEmail, stripeCustomerId, formDataStore) {
	const formData = formDataStore.getFormData();
	try {
		const { clientSecret, stripe_customer_id } = await createSetupIntent(stripeAccountId, customerEmail);


		const options = {
			clientSecret,
			appearance: {
				theme: "night",
				variables: {
					colorPrimary: "#0570de",
					colorBackground: "#ffffff",
					colorText: "#30313d",
					colorDanger: "#df1b41",
					fontFamily: "Ideal Sans, system-ui, sans-serif",
					spacingUnit: "2px",
					borderRadius: "4px"
				}
			}
		};

		const elements = stripe.elements(options);

		const paymentElement = elements.create("payment", {
			fields: {
				billingDetails: "auto"
			},
			wallets: {
				applePay: "auto",
				googlePay: "auto"
			}
		});
		paymentElement.mount("#payment-element");
		document.getElementById("save-payment-method").addEventListener("click", async () => {
			console.log("Payment element exists:", !!document.getElementById("payment-element"));
			try {
				const { setupIntent, error } = await stripe.confirmSetup({
					elements,
					confirmParams: {
						return_url: "https://example.com/account"
					},
					redirect: "if_required"
				});
				if (error) {
					console.error("Error confirming setup:", error);
					throw error;
				}
				console.log("SetupIntent:", setupIntent);
				await attachPaymentMethodToCustomer(setupIntent.payment_method, stripeCustomerId);
				notificationUI.sendNotification({
					type: "SUCCESS",
					title: "Payment method saved successfully",
					save: true
				});
				$("#stripe-elements-container").hide();
				$(".add-payment-method-container").show();
				await fetchAndRenderSavedPaymentMethods(stripeCustomerId, customerEmail, formDataStore);
			} catch (error) {
				console.error("Error saving payment method:", error);
				notificationUI.sendNotification({
					type: "ERROR",
					title: "Failed to save payment method. Please try again.",
					save: false
				});
				$("#payment-element").empty();
			}
		});
	} catch (error) {
		console.error("Error mounting Stripe elements:", error);
		alert(`Failed to initialize payment form: ${error.message}`);
	}
}