import $ from "jquery";

export function renderLateFeeTable(field, formDataStore) {
	const formData = formDataStore.getFormData();
	//console.log("Rendering late fee table", field, formData);
	const wrapper = $("<div class='fee-table late-fee-table' data-field-key='" + field.key + "'></div>");
	let rows = formData[field.key] || [{ from: 0, to: 99999, static: 0, dynamic: 0, max: 0, min: 0 }];
	const chargeFeeCurrent = formData.charge_late_fee === "yes";
	const gracePeriod = parseInt(formData.grace_period_days || "0");
	const feeStructure = formData.fee_structure_late_fee || "static";

	function createTable() {
		const table = $("<table class='fee-table-grid'></table>");
		const headerRow = $("<tr></tr>");
		["From Day", "To Day", "Static Part ($)", "Dynamic Part (%)", "Minimum ($)", "Maximum ($)", "Action"].forEach(header => {
			headerRow.append(`<th>${header}</th>`);
		});
		table.append(headerRow);
		return table;
	}

	function renderRow(row, index) {
		const tr = $("<tr></tr>");
		const isGracePeriodRow = index === 0 && gracePeriod > 0;
		const isLastRow = index === rows.length - 1;

		["from", "to", "static", "dynamic", "min", "max"].forEach(key => {
			const input = $(`<input type="number" name="${field.key}[${index}][${key}]" value="${row[key]}" step="0.01">`);

			if (key === "from" || (isGracePeriodRow) || (key === "to" && isLastRow && feeStructure === "static")) {
				input.prop("readonly", true);
				input.addClass("readonly-input");
			}
			if (key === "dynamic") {
				input.attr("max", "100");
			}
			input.on("change", function () {
				const newValue = key === "from" || key === "to" ? Math.floor(parseFloat($(this).val()) || 0) : parseFloat($(this).val()) || 0;
				row[key] = newValue;
				$(this).val(newValue);
				validateAndUpdateRows(index, key);
			});

			tr.append($("<td></td>").append(input));
		});

		const actionTd = $("<td></td>");
		if (!isGracePeriodRow && !isLastRow && feeStructure === "dynamic") {
			const removeButton = $("<button type='button' class='remove-row'>Remove</button>");
			removeButton.on("click", function () {
				rows.splice(index, 1);
				validateAndUpdateRows();
			});
			actionTd.append(removeButton);
		}
		tr.append(actionTd);

		return tr;
	}

	function validateAndUpdateRows(changedIndex, changedKey) {
		if (!chargeFeeCurrent) {
			rows = [{ from: 0, to: 99999, static: 0, dynamic: 0, min: 0, max: 0 }];
		} else {
			// Handle grace period
			if (gracePeriod > 0) {
				if (rows.length === 0 || rows[0].to !== gracePeriod) {
					rows = [
						{ from: 0, to: gracePeriod, static: 0, dynamic: 0, min: 0, max: 0 },
						...rows.filter(row => row.from >= gracePeriod)
					];
					if (rows.length === 1) {
						rows.push({ from: gracePeriod, to: 99999, static: 0, dynamic: 0, min: 0, max: 0 });
					}
				}
			} else if (rows[0].from !== 0) {
				rows[0].from = 0;
			}

			// Handle fee structure
			if (feeStructure === "static") {
				rows = rows.slice(0, gracePeriod > 0 ? 2 : 1);
				rows[rows.length - 1].to = 99999;
			} else if (feeStructure === "dynamic") {
				// If 'to' was changed and it's not the last row, add a new row
				if (changedKey === "to" && changedIndex < rows.length) {
					const newRow = { from: rows[changedIndex].to, to: rows[changedIndex + 1]?.from ?? 99999, static: rows[changedIndex]?.static ?? 0, dynamic: rows[changedIndex]?.dynamic ?? 0, max: rows[changedIndex]?.max ?? 0, min: rows[changedIndex]?.min ?? 0 };
					rows.splice(changedIndex + 1, 0, newRow);
				}
			}

			// Validate and update all rows
			for (let i = 0; i < rows.length; i++) {
				if (i > 0) {
					rows[i].from = rows[i - 1].to;
				}
				rows[i].to = Math.min(99999, Math.max(rows[i].from + 1, rows[i].to)); // Ensure 'to' cannot be greater than 99999
				rows[i].static = Math.max(0, parseFloat(rows[i].static) || 0);
				rows[i].dynamic = Math.min(100, Math.max(0, parseFloat(rows[i].dynamic) || 0));
				rows[i].min = Math.max(0, Math.min(parseFloat(rows[i].min) || 0, parseFloat(rows[i].max) || 0));
				rows[i].max = Math.max(parseFloat(rows[i].static) || 0, parseFloat(rows[i].min) || 0, parseFloat(rows[i].max) || 0);

				// Validation: 'from' cannot be greater than 'to'
				if (rows[i].from >= rows[i].to) {
					rows.splice(i, 1); // Remove invalid row
					i--; // Adjust index after removal
					continue;
				}

				// Validation: each row's 'from' should be greater than previous row's 'to'
				if (i > 0 && rows[i].from != rows[i - 1].to) {
					rows.splice(i, 1); // Remove invalid row
					i--; // Adjust index after removal
				}

				if (i === rows.length - 1) {
					rows[i].to = 99999;
				}
			}
		}

		updateTable();
	}


	function updateTable() {
		const table = createTable();
		wrapper.empty().append(table);
		rows.forEach((row, index) => {
			table.append(renderRow(row, index));
		});
		// if (feeStructure === "dynamic" && chargeFeeCurrent) {
		// 	const addButton = $("<button type='button' class='add-row'>Add New Row</button>");
		// 	const lastRow = rows[rows.length - 1];
		// 	if (!(lastRow.from == 99998 && lastRow.to == 99999)) { 
		// 		addButton.on("click", function () {
		// 			rows[rows.length - 1].to = lastRow.to - 1;
		// 			rows.push({ from: lastRow.to, to: 99999, static: lastRow.static, dynamic: lastRow.dynamic, min: lastRow.min, max: lastRow.max });
		// 			validateAndUpdateRows();
		// 		});
		// 		wrapper.append(addButton);
		// 	}
		// }
		formData[field.key] = rows;
	}

	validateAndUpdateRows();
	return wrapper;
}