// apiClient.js
/* global notificationUI */
import loaderGif from "../assets/loader.gif";

// Loader management
let activeRequests = 0;
const loader = document.createElement("div");
loader.innerHTML = `<img src="${loaderGif}" alt="Loading..." />`;
loader.style.position = "fixed";
loader.style.top = "50%";
loader.style.left = "50%";
loader.style.transform = "translate(-50%, -50%)";
loader.style.display = "none";
loader.style.zIndex = "9999";

// Append loader to body when DOM is ready
if (document.body) {
	document.body.appendChild(loader);
} else {
	window.addEventListener("DOMContentLoaded", () => {
		document.body.appendChild(loader);
	});
}

function showLoader() {
	activeRequests++;
	loader.style.display = "block";
}

function hideLoader() {
	activeRequests--;
	if (activeRequests <= 0) {
		activeRequests = 0;
		loader.style.display = "none";
	}
}

export async function apiClient(url, options = {}, auth_required = true) {
	const token = localStorage.getItem("authToken");
	const cleanToken = token ? token.replace(/^Bearer\s+/i, "") : "";
	if (!token && auth_required) {
		console.warn("No authentication token found. Please sign in.");
		throw new Error("Authentication required");
	}
	const defaultOptions = {
		method: "GET",
		headers: {
			"Authorization": cleanToken,
			"Content-Type": "application/json"
		}
	};

	const mergedOptions = {
		...defaultOptions,
		...options,
		headers: {
			...defaultOptions.headers,
			...options.headers
		}
	};

	if (mergedOptions.method !== "GET" && mergedOptions.body && typeof mergedOptions.body === "object") {
		mergedOptions.body = JSON.stringify(mergedOptions.body);
	}

	showLoader(); // Show loader before making the request

	try {
		console.log("API request:", url, mergedOptions);
		const response = await fetch(url, mergedOptions);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const contentType = response.headers.get("content-type");
		if (contentType && contentType.includes("application/json")) {
			const sendResponse = await response.json();
			if (notificationUI && notificationUI.isInitialized()) {
				notificationUI.handleApiResponse(sendResponse);
			}
			console.log("API response:", sendResponse);
			return sendResponse;
		}
		return await response.text();
	} catch (error) {
		console.error("API request failed:", error, url, mergedOptions);
		throw error;
	} finally {
		hideLoader(); // Hide loader after the request is complete (success or failure)
	}
}