import { getNextDynamicDate } from "./dateSelector";

export function generateAmortizationSchedule(totalAmount, downPaymentAmount, interestRate, numInstallments, startDate, scheduleType, staticDay, weekOfMonth, dayOfWeek) {
	console.log("Generating amortization schedule", totalAmount, interestRate, numInstallments, startDate, scheduleType, staticDay, weekOfMonth, dayOfWeek);
	const schedule = [];
	let remainingBalance = totalAmount - downPaymentAmount;
	const monthlyPayment = calculateInstallmentAmount(totalAmount - downPaymentAmount, interestRate, numInstallments);

	for (let i = 0; i < numInstallments; i++) {
		let interestPayment = 0;
		let principalPayment = monthlyPayment;

		if (interestRate > 0) {
			const monthlyRate = interestRate / 12 / 100;
			interestPayment = remainingBalance * monthlyRate;
			principalPayment = monthlyPayment - interestPayment;
		}

		remainingBalance -= principalPayment;

		const paymentDate = scheduleType == "static" ? new Date(startDate) : getNextDynamicDate(startDate, weekOfMonth, dayOfWeek);

		schedule.push({
			installmentNumber: i + 1,
			dueDate: paymentDate.toISOString().split("T")[0],
			totalAmount: monthlyPayment,
			principalComponent: principalPayment,
			interestComponent: interestPayment,
			remainingPrincipal: remainingBalance
		});
		startDate = scheduleType == "static" ? paymentDate.setMonth(paymentDate.getMonth() + 1) : paymentDate.setDate(paymentDate.getDate() + 1);
	}

	return schedule;
}

function calculateInstallmentAmount(totalAmount, interestRate, numInstallments) {
	if (interestRate === 0) {
		return totalAmount / numInstallments;
	}

	const monthlyRate = interestRate / 12 / 100;
	return (totalAmount * monthlyRate * Math.pow(1 + monthlyRate, numInstallments)) / (Math.pow(1 + monthlyRate, numInstallments) - 1);
}

function validateForm(formData) {
	if (isNaN(parseFloat(formData.total_amount)) || parseFloat(formData.total_amount) <= 0) {
		return "Total amount is missing or invalid.";
	}
	if (isNaN(parseFloat(formData.interest_rate))) {
		return "Interest rate is missing or invalid.";
	}
	if (!(new Date(formData.start_date) instanceof Date) || isNaN(new Date(formData.start_date))) {
		return "Start date is missing or invalid.";
	}
	if (isNaN(parseInt(formData.num_installments)) || parseInt(formData.num_installments) <= 0) {
		return "Number of installments is missing or invalid.";
	}
	return null;
}

export function showAmortizationSchedule(formDataStore) {
	const formData = formDataStore.getFormData();
	const errorMessage = validateForm(formData);
	let modalContent = "";

	if (!errorMessage) {
		const schedule = generateAmortizationSchedule(
			parseFloat(formData.total_amount),
			parseFloat(formData.downpayment_amount),
			parseFloat(formData.interest_rate),
			parseInt(formData.num_installments),
			new Date(formData.start_date),
			formData.schedule_type,
			formData.static_day,
			formData.week_of_month,
			formData.day_of_week
		);

		modalContent = `
            <div class="modal-background"></div>
            <div class="modal-content">
                <h2>Amortization Schedule (All amounts are in Currency: ${formData.currency_code})</h2>
                <table class="fee-table-grid">
                    <thead>
                        <tr>
                            <th>Installment</th>
                            <th>Due Date</th>
                            <th>Monthly Payment</th>
                            <th>Principal</th>
                            <th>Interest</th>
                            <th>Remaining Principal</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${schedule.map(row => `
                            <tr>
                                <td>${row.installmentNumber}</td>
                                <td>${row.dueDate}</td>
                                <td>${row.totalAmount.toFixed(2)}</td>
                                <td>${row.principalComponent.toFixed(2)}</td>
                                <td>${row.interestComponent.toFixed(2)}</td>
                                <td>${row.remainingPrincipal.toFixed(2)}</td>
                            </tr>
                        `).join("")}
                    </tbody>
                </table>
                <button class="close-modal btn btn-secondary">Close</button>
            </div>
        `;
	} else {
		modalContent = `
            <div class="modal-background"></div>
            <div class="modal-content">
                <h2>Error</h2>
                <p>${errorMessage}</p>
                <button class="close-modal btn btn-secondary">Close</button>
            </div>
        `;
	}

	const modal = $("<div class='modal'></div>").html(modalContent);
	$("body").append(modal);

	$(".close-modal, .modal-background").on("click", function () {
		modal.remove();
	});
}


