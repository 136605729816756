/* global notificationUI */
import $ from "jquery";
import { renderForm } from "./formRenderer";
import { apiClient } from "./apiClient";
import { loadConnectAndInitialize } from "@stripe/connect-js";
const API_BASE_URL = "/api/business/auth";
import { NotificationUI } from "./notifications";
import logoSrc from "../assets/logo.png";
import FormDataStore from "./FormDataStore.js";
function renderNavigation() {
	const token = localStorage.getItem("authToken");
	if (token) {
		$("#mainNav").html(`
            <a href="#" id="dashboardLink">Dashboard</a>
			<a href="#" id="paymentPlanLink">Create Payment Plan</a>
            <a href="#" id="logoutLink">Logout</a>
        `);
	} else {
		$("#mainNav").html(`
            <a href="#" id="loginLink">Login</a>
            <a href="#" id="signupLink">Sign Up</a>
            <a href="#" id="forgotPasswordLink">Forgot Password</a>
        `);
	}
}

function renderMainPage() {
	$("#mainContent").html(`
        <h2>Welcome to PaisaNova - Casanova of Payment Plans!</h2>
        <p>Please login or sign up to continue.</p>
    `);
}

function renderDashboard() {
	const tabContainer = $("#tabContainer");
	tabContainer.hide();
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const businessInfo = JSON.parse(localStorage.getItem("business_info"));
	let stripeOnboardingStatus = "";
	if (businessInfo && businessInfo.stripe_account_id) {
		if (businessInfo.stripe_details && businessInfo.stripe_details.charges_enabled) {
			stripeOnboardingStatus = "Stripe account is fully onboarded.";
		} else {
			stripeOnboardingStatus = `
                <p>Stripe onboarding is incomplete.</p>
                <a href="#" id="completeStripeOnboarding" class="btn btn-primary">Complete Stripe Onboarding</a>
            `;
		}
	} else {
		stripeOnboardingStatus = "Stripe account not set up.";
	}

	$("#mainContent").html(`
        <h2>Welcome, ${userInfo.first_name}!</h2>
        <p>Business Name: ${businessInfo.business_name}</p>
        <p>Stripe Status: ${stripeOnboardingStatus}</p>
    `);
}

async function fetchClientSecret(connectedAccountId) {
	try {
		const response = await apiClient(`${API_BASE_URL}/stripe/account_session`, {
			method: "POST",
			body: JSON.stringify({ account: connectedAccountId })
		});
		return response.client_secret;
	} catch (error) {
		console.error("Error fetching client secret:", error);
		throw error;
	}
}

async function renderLoginForm() {
	console.log("Login Form render");
	try {
		const formConfig = await apiClient(`${API_BASE_URL}/forms?name=login`, {}, false);
		const formDataStore = new FormDataStore();
		const { form, validateForm } = renderForm(formConfig, formDataStore);
		$("#mainContent").html(form);
		form.on("submit", function (e) {
			e.preventDefault();
			const formData = $(this).serializeArray().reduce((obj, item) => {
				obj[item.name] = item.value;
				return obj;
			}, {});
			if (validateForm(form, 0)) {
				handleLogin(formData, formConfig);
			}
		});
	} catch (error) {
		console.error("Error rendering login form:", error);
	}
}

async function renderSignupForm() {
	console.log("SignUp Form render");
	try {
		const formConfig = await apiClient(`${API_BASE_URL}/forms?name=register_business`, {}, false);
		const formDataStore = new FormDataStore();
		const { form, validateForm } = renderForm(formConfig, formDataStore );
		$("#mainContent").html(form);
		form.on("submit", function (e) {
			e.preventDefault();
			const formData = $(this).serializeArray().reduce((obj, item) => {
				obj[item.name] = item.value;
				return obj;
			}, {});
			if (validateForm(form, 0)) {
				handleSignup(formData, formConfig);
			}
		});
	} catch (error) {
		console.error("Error rendering signup form:", error);
	}
	
}

async function renderForgotPasswordForm() {
	try {
		const formConfig = await apiClient(`${API_BASE_URL}/forms?name=forgot_password`, {}, false);
		const formDataStore = new FormDataStore();
		const { form, validateForm } = renderForm(formConfig, formDataStore );
		$("#mainContent").html(form);
		form.on("submit", function (e) {
			e.preventDefault();
			const formData = $(this).serializeArray().reduce((obj, item) => {
				obj[item.name] = item.value;
				return obj;
			}, {});
			if (validateForm(form, 0)) {
				handleForgotPassword(formData, formConfig);
			}
		});
	} catch (error) {
		console.error("Error rendering forgot password form:", error);
	}
}

async function renderPaymentPlanForm() {
	try {
		const formConfig = await apiClient(`${API_BASE_URL}/forms?name=payment_plan`, {}, false);
		const formDataStore = new FormDataStore({ payment_methods: [], feeFields: {} });
		const { form, validateForm } = renderForm(formConfig, formDataStore );
		$("#mainContent").html(form);
		form.on("submit", function (e) {
			e.preventDefault();
			const formData = formDataStore.getFormData();
			if (validateForm(form, 0)) {
				handlePaymentPlan(formData, formConfig);
			}
		});
	} catch (error) {
		console.error("Error rendering forgot password form:", error);
	}
}

async function handlePaymentPlan(formData, formConfig) {
	console.log("formData", formData);
	try {
		if (formConfig?.submitActionObject?.endpoint && formData) {
			const response = await apiClient(formConfig.submitActionObject.endpoint, {
				method: "POST",
				body: JSON.stringify(formData)
			}, false);
			renderNavigation();
			renderDashboard();
			notificationUI.sendNotification({
				type: "SUCCESS",
				title: "Payment Plan Created Successfully",
				save: true
			});
		} else {
			console.log("No endpoint specified in form config", formData);
		}
	} catch (error) {
		notificationUI.sendNotification({
			type: "ERROR",
			title: "Payment Plan Creation Failed",
			message: "Please try again."
		});
		console.error("Registration error:", error);
	}
}

let notificationUI;

function initializeNotifications() {
	if (!notificationUI) {
		notificationUI = new NotificationUI();
		window.notificationUI = notificationUI;
	}
}

async function handleLogin(formData, formConfig) {
	console.log("formData", formData);
	try {
		if (formConfig?.submitActionObject?.endpoint && formData) {
			const response = await apiClient(formConfig.submitActionObject.endpoint, {
				method: "POST",
				body: JSON.stringify(formData)
			}, false);
			localStorage.setItem("authToken", response.token);
			localStorage.setItem("user_info", JSON.stringify(response.user_info));
			localStorage.setItem("business_info", JSON.stringify(response.business_info));
			console.log("User logged in, setting notification UI");
			notificationUI.setLoggedIn(true);
			renderNavigation();
			renderDashboard();
			notificationUI.sendNotification({
				type: "SUCCESS",
				title: "Login Successful",
				save: false
			});
		} else {
			console.log("No endpoint specified in form config", formData);
		}
	} catch (error) {
		notificationUI.sendNotification({
			type: "ERROR",
			title: "Login Failed",
			message: "Please try again."
		});
		console.error("Login error:", error);
	}
}

async function handleSignup(formData, formConfig) {
	console.log("formData", formData);
	try {
		if (formConfig?.submitActionObject?.endpoint && formData) {
			const response = await apiClient(formConfig.submitActionObject.endpoint, {
				method: "POST",
				body: JSON.stringify(formData)
			}, false);
			localStorage.setItem("authToken", response.token);
			localStorage.setItem("user_info", JSON.stringify(response.user_info));
			localStorage.setItem("business_info", JSON.stringify(response.business_info));
			console.log("User logged in, setting notification UI");
			notificationUI.setLoggedIn(true);
			renderNavigation();
			renderDashboard();
			notificationUI.sendNotification({
				type: "SUCCESS",
				title: "Registration Successful",
				save: false
			});
		} else {
			console.log("No endpoint specified in form config", formData);
		}
	} catch (error) {
		notificationUI.sendNotification({
			type: "ERROR",
			title: "Registration Failed",
			message: "Please try again."
		});
		console.error("Registration error:", error);
	}
}

async function verifyAuthToken(initializeNotification = true) {
	const token = localStorage.getItem("authToken");
	if (!token) {
		notificationUI.setLoggedIn(false);
		renderMainPage();
		return;
	}
	try {
		const response = await apiClient(`${API_BASE_URL}/login`, {
			method: "POST",
			body: JSON.stringify({ token })
		}, false);
		localStorage.setItem("authToken", response.token);
		localStorage.setItem("user_info", JSON.stringify(response.user_info));
		localStorage.setItem("business_info", JSON.stringify(response.business_info));
		if (initializeNotification) {
			await notificationUI.setLoggedIn(true);
		}
		renderNavigation();
		renderDashboard();
	} catch (error) {
		notificationUI.sendNotification({
			type: "ERROR",
			title: "Token Expired",
			message: "Token has Expired. Please login again."
		});
		console.error("Token verification error:", error);
		handleLogout();
	}
}

async function handleForgotPassword(formData, formConfig) {
	try {
		const response = await apiClient(formConfig.submitActionObject.endpoint, {
			method: "POST",
			body: JSON.stringify(formData)
		}, false);
		notificationUI.sendNotification({
			type: "SUCCESS",
			title: "Password Reset",
			message: "Password reset instructions have been sent to your email."
		});
		renderLoginForm();
	} catch (error) {
		notificationUI.sendNotification({
			type: "ERROR",
			title: "Password Reset Failed",
			message: "Failed to process forgot password request. Please try again."
		});
		console.error("Forgot password error:", error);
	}
}
async function handleStripeOnboarding() {
	const businessInfo = JSON.parse(localStorage.getItem("business_info"));
	const connectedAccountId = businessInfo.stripe_account_id;

	if (!connectedAccountId) {
		notificationUI.sendNotification({
			type: "ERROR",
			title: "There is something wrong with your business registration. Please complete it first.",
			message: "No Stripe account ID found. Please complete registration first."
		});
		return;
	}

	try {
		$("#mainContent").html(`
            <h2>Complete Your Stripe Onboarding</h2>
            <div id="embedded-onboarding-container"></div>
        `);
		const instance = await loadConnectAndInitialize({
			publishableKey: "pk_test_51PYXaw2K8BerXPF2YhCnVDY2I3J9uCypNl1UYFjcB2JjnNpGpfb9WpLmTrXZnRvJXmJR9LYqTITOhgtitf3qOyWL00FyF6zDOV",
			fetchClientSecret: () => fetchClientSecret(connectedAccountId),
			appearance: {
				overlays: "dialog",
				variables: {
					colorPrimary: "#635BFF"
				}
			}
		});
		const container = document.getElementById("embedded-onboarding-container");
		const embeddedOnboardingComponent = instance.create("account-onboarding");
		embeddedOnboardingComponent.setOnExit(() => {
			console.log("User exited the onboarding flow");
			verifyAuthToken(false); // Refresh dashboard after onboarding
		});
		container.appendChild(embeddedOnboardingComponent);

	} catch (error) {
		console.error("Failed to initialize Stripe onboarding:", error);
		alert(`Error: ${error.message}. Please try again later.`);
	}
}

function handleLogout() {
	localStorage.removeItem("authToken");
	localStorage.removeItem("user_info");
	localStorage.removeItem("business_info");
	const tabContainer = $("#tabContainer");
	tabContainer.hide();
	window.notificationUI.setLoggedIn(false);
	renderNavigation();
	renderMainPage();
}

async function renderCustomForm(name) {
	console.log("Custom Form render", name);
	try {
		const formConfig = await apiClient(`${API_BASE_URL}/forms?name=${name}`);
		console.log("Form Config:", formConfig);
		const { form, validateForm } = renderForm(formConfig, new FormDataStore() );
		$("#mainContent").html(form);

		form.on("submit", async function (event) {
			console.log("Form submitted:", form.serializeArray());
			event.preventDefault();

			if (validateForm(form)) {
				const formData = form.serializeArray().reduce((obj, item) => {
					obj[item.name] = item.value;
					return obj;
				}, {});

				const submitActionObject = formConfig.submitActionObject;

				if (submitActionObject) {
					if (submitActionObject.console) {
						console.log("Form Data:", formData);
					}

					if (submitActionObject.endpoint) {
						try {
							await apiClient(`${API_BASE_URL}${submitActionObject.endpoint}`, {
								method: "POST",
								body: JSON.stringify(formData)
							});
							notificationUI.sendNotification({
								type: "SUCCESS",
								title: "Form Submitted",
								message: "Form submitted successfully!"
							});
							renderDashboard();
						} catch (error) {
							notificationUI.sendNotification({
								type: "ERROR",
								title: "Form Submission Failed",
								message: "Please try again."
							});
							console.error("Form submission error:", error);
						}
					} else {
						console.log("No endpoint provided, form data not submitted.");
					}
				} else {
					console.log("No submitActionObject defined, form data:", formData);
				}
			}
		});
	} catch (error) {
		console.error("Error rendering custom form:", error);
	}
}

$(document).ready(function () {
	initializeNotifications();
	renderNavigation();
	const logoImg = document.createElement("img");
	logoImg.src = logoSrc;
	logoImg.alt = "PaisaNova";
	logoImg.style.maxHeight = "100%";
	logoImg.style.width = "300px";

	const logoLink = document.getElementById("logoLink");
	logoLink.innerHTML = "";
	logoLink.appendChild(logoImg);
	logoLink.addEventListener("click", (event) => {
		event.preventDefault();
		// Add your dashboard navigation logic here
		renderDashboard();
	});
	verifyAuthToken();

	$(document).on("click", "#loginLink", renderLoginForm);
	$(document).on("click", "#signupLink", renderSignupForm);
	$(document).on("click", "#paymentPlanLink", renderPaymentPlanForm);
	$(document).on("click", "#forgotPasswordLink", renderForgotPasswordForm);
	$(document).on("click", "#dashboardLink", renderDashboard);
	$(document).on("click", "#logoutLink", handleLogout);
	$(document).on("click", "#completeStripeOnboarding", handleStripeOnboarding);
});