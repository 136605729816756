import { renderStripeElements } from "./stripeLogic";
import { renderTransactionFeeTable } from "./transactionFeeHandler.js";
import { renderLateFeeTable } from "./lateFeeHandler.js";
import { renderDateChangeFeeTable } from "./dateChangeFeeHandler.js";
export function setupSearch(input, options, formDataStore) {
	const token = localStorage.getItem("authToken");
	let debounceTimer;

	$(document).on(options.on || "keyup", `#${input.attr("id")}`, function (event) {
		clearTimeout(debounceTimer);
		debounceTimer = setTimeout(() => {
			const query = $(this).val();
			if (query.length >= 3) {
				$.ajax({
					url: options.endpoint,
					method: "GET",
					data: { query: query },
					headers: {
						"Authorization": `${token}`
					},
					success: function (data) {
						const results = data.results;
						const suggestionsBox = $("<div class='suggestions-box'></div>");
						if (results && results.length > 0) {
							results.forEach(item => {
								const labelText = options.dropdown_label.replace(/\${(\w+)}/g, (match, key) => item[key] || "");
								const suggestion = $(`<div class="suggestion">${labelText}</div>`);
								suggestion.on("click", function () {
									console.log("Selected suggestion:", item);
									input.val(labelText);
									suggestionsBox.remove();
									formDataStore.updateFormData({
										existing_customer_search: { value: labelText, isValid: true, customer: item },
										customer_id: item.customer_id,
										customer_email: item.email,
										customer_mobile: item.mobile,
										customer_first_name: item.first_name,
										customer_last_name: item.last_name
									});
									input.trigger("change");
									renderStripeElements(formDataStore);
								});
								suggestionsBox.append(suggestion);
							});
						} else {
							suggestionsBox.append("<div class='no-results'>No results found</div>");
							formDataStore.updateFormData({
								existing_customer_search: { value: "", isValid: false },
								customer_id: null,
								customer_email: null,
								customer_mobile: null,
								customer_first_name: null,
								customer_last_name: null
							});
							hideCustomerFields();
						}
						input.siblings(".suggestions-box").remove();
						input.after(suggestionsBox);
					},
					error: function (error) {
						console.error("Error in search:", error);
					}
				});
			} else {
				input.siblings(".suggestions-box").remove();
			}
		}, options.debounce || 300);
	});
}

export function hideCustomerFields() {
	$("#customer_id").val("").prop("readonly", false).closest(".form-group").hide();
	$("#customer_first_name").val("").prop("readonly", false).closest(".form-group").hide();
	$("#customer_last_name").val("").prop("readonly", false).closest(".form-group").hide();
	$("#customer_email").val("").prop("readonly", false).closest(".form-group").hide();
	$("#customer_mobile").val("").prop("readonly", false).closest(".form-group").hide();
}

export function showNewCustomerFields() {
	$("#customer_id").val("").prop("readonly", true).closest(".form-group").hide();
	$("#customer_email").val("").prop("readonly", false).closest(".form-group").show();
	$("#customer_mobile").val("").prop("readonly", false).closest(".form-group").show();
	$("#customer_first_name").val("").prop("readonly", false).closest(".form-group").show();
	$("#customer_last_name").val("").prop("readonly", false).closest(".form-group").show();
}

export function showOldCustomerFields(customer) {
	console.log("Showing old customer fields:", customer);
	$("#customer_id").val(customer.customer_id).prop("readonly", true).closest(".form-group").show();
	$("#customer_first_name").val(customer.first_name).prop("readonly", true).closest(".form-group").show();
	$("#customer_last_name").val(customer.last_name).prop("readonly", true).closest(".form-group").show();
	$("#customer_email").val(customer.email).prop("readonly", true).closest(".form-group").show();
	$("#customer_mobile").val(customer.mobile).prop("readonly", true).closest(".form-group").show();
}

export function setupExistenceCheck(input, options, formDataStore) {
	const token = localStorage.getItem("authToken");
	const checkExistenceHandler = function () {
		checkExistence(input, options, token, formDataStore);
	};

	if (options.on === "next") {
		$(document).on("click", ".btn-next", function () {
			if ($(this).closest("form").find(`#${input.attr("id")}`).length) {
				checkExistenceHandler();
			}
		});
	} else if (options.on === "blur") {
		$(document).on("blur", `#${input.attr("id")}`, checkExistenceHandler);
	}
}


const checkExistence = (input, options, token, formDataStore) => {
	const formData = formDataStore.getFormData();
	console.log("Checking existence:", input, formData);
	const value = input.val();
	if (value && (formData.customer_id === undefined || formData.customer_id === null || formData.customer_id === "")) {
		$.ajax({
			url: options.endpoint,
			method: "GET",
			data: { [options.internal_type]: value },
			headers: {
				"Authorization": `${token}`
			},
			success: function (data) {
				if (data.exists === options.error_if_return) {
					input.addClass("invalid");
					input.siblings(".error-message").remove();
					input.after(`<div class="error-message">${options.error_message || `This ${options.internal_type} already exists`}</div>`);
				} else {
					input.removeClass("invalid");
					input.siblings(".error-message").remove();
				}
			},
			error: function (error) {
				console.error("Error in existence check:", error);
			}
		});
	} else {
		input.removeClass("invalid");
		input.siblings(".error-message").remove();
	}
};

export function showTimePicker(input) {
	const currentTime = input.val() || "00:00";
	const [currentHour, currentMinute] = currentTime.split(":").map(Number);

	const picker = $(`
        <div class="time-picker">
            <div class="time-picker-row">
                <button class="time-picker-btn" data-action="hour-up">▲</button>
                <span class="time-picker-display hour">${currentHour.toString().padStart(2, "0")}</span>
                <button class="time-picker-btn" data-action="hour-down">▼</button>
            </div>
            <div class="time-picker-separator">:</div>
            <div class="time-picker-row">
                <button class="time-picker-btn" data-action="minute-up">▲</button>
                <span class="time-picker-display minute">${currentMinute.toString().padStart(2, "0")}</span>
                <button class="time-picker-btn" data-action="minute-down">▼</button>
            </div>
            <button class="time-picker-done">Done</button>
        </div>
    `);

	input.after(picker);

	$(document).on("click", ".time-picker-btn", function (e) {
		e.stopPropagation();
		e.preventDefault();
		const action = $(this).data("action");
		const display = $(this).siblings(".time-picker-display");
		let value = parseInt(display.text(), 10);

		// eslint-disable-next-line default-case
		switch (action) {
		case "hour-up":
			value = (value + 1) % 24;
			break;
		case "hour-down":
			value = (value - 1 + 24) % 24;
			break;
		case "minute-up":
			value = (value + 1) % 60;
			break;
		case "minute-down":
			value = (value - 1 + 60) % 60;
			break;
		}

		display.text(value.toString().padStart(2, "0"));
	});

	$(document).on("click", ".time-picker-done", function (e) {
		e.stopPropagation();
		e.preventDefault();
		const hour = picker.find(".hour").text();
		const minute = picker.find(".minute").text();
		input.val(`${hour}:${minute}`);
		picker.remove();
	});

	$(document).on("click", function (e) {
		if (!picker.is(e.target) && picker.has(e.target).length === 0 && !input.is(e.target)) {
			picker.remove();
		}
	});
}

export function isFieldVisible(field, formConfig, formDataStore) {
	const formData = formDataStore.getFormData();
	if (!formConfig.conditionalRules) {
		return true;
	}
	const rule = formConfig.conditionalRules.find(r => r.field === field.key);
	if (!rule) {
		return true;
	}
	const showType = rule.show_type || "and";

	const evaluateExpectedValue = (dependentValue, expectedValue) => {
		if (Array.isArray(expectedValue)) {
			const joinType = expectedValue.join_type || "or";
			const compareValues = (eqValue) =>
				dependentValue === eqValue ||
				(Array.isArray(dependentValue) && dependentValue.includes(eqValue));

			if (joinType === "and") {
				return expectedValue.every(compareValues);
			}
			return expectedValue.some(compareValues);

		}
		return dependentValue === expectedValue ||
			(Array.isArray(dependentValue) && dependentValue.includes(expectedValue));
	};

	const evaluateCondition = (condition) => {
		const [dependentField, expectedValue] = Object.entries(condition)[0];
		const dependentValue = formData[dependentField];
		if (dependentField === "existing_customer_search" && expectedValue === "*") {
			console.log("dependent field is existing_customer_search: ", formData);
			return dependentValue && dependentValue.isValid;
		}
		if (expectedValue === "*") {
			return dependentValue !== undefined && dependentValue !== "";
		}
		return evaluateExpectedValue(dependentValue, expectedValue);
	};
	if (showType === "or") {
		return rule.show.some(evaluateCondition);
	}
	return rule.show.every(evaluateCondition);

}

export function updateVisibility(name, value, form, formConfig, formDataStore) {
	const formData = formDataStore.getFormData();
	formConfig.pages.forEach(page => {
		page.panels.forEach(panel => {
			panel.components.forEach(field => {
				const fieldDiv = form.find(`.form-field[data-field-key="${field.key}"]`);
				if (isFieldVisible(field, formConfig, formDataStore)) {
					if (field.customType === "fee_table" && field.feeType === "transaction") {
						const paymentMethod = field.key.includes("credit") ? "credit_card" : (field.key.includes("debit") ? "debit_card" : (field.key.includes("ach") ? "ach" : null));
						if (!name || !value || (name && value && field.key.includes(value)) || (paymentMethod && field.key.includes(paymentMethod))) {
							if (paymentMethod && formData.payment_methods && formData.payment_methods.includes(paymentMethod)) {
								fieldDiv.show();
								fieldDiv.find(":input").prop("required", field.validate && field.validate.required);
							} else {
								fieldDiv.hide();
								fieldDiv.find(":input").prop("required", false);
							}
							updateTransactionFeeTable(field, form, formDataStore);
						}
					} else {
						fieldDiv.show();
						fieldDiv.find(":input").prop("required", field.validate && field.validate.required);
					}
				} else {
					fieldDiv.hide();
					fieldDiv.find(":input").prop("required", false);
				}
			});
		});
	});
}

export function updateTransactionFeeTable(field, form, formDataStore) {
	const formData = formDataStore.getFormData();
	const feeTable = form.find(`.fee-table[data-field-key="${field.key}"]`);
	if (feeTable.length) {
		const newTable = renderTransactionFeeTable(field, formData);
		feeTable.replaceWith(newTable);
	}
}

export function updateFeeTable(changedField, value, form, formDataStore) {
	const formData = formDataStore.getFormData();
	const feeFields = formData.feeFields;
	Object.entries(feeFields).forEach(([key, field]) => {
		const feeTable = form.find(`.fee-table[data-field-key="${key}"]`);
		if (feeTable.length) {
			let shouldUpdate = false;
			// eslint-disable-next-line default-case
			switch (field.feeType) {
			case "late":
				shouldUpdate = ["charge_late_fee", "grace_period_days", "fee_structure_late_fee"].includes(changedField);
				break;
			case "transaction":
				shouldUpdate = ["charge_transaction_fee", "fee_structure_credit_card", "payment_methods", "fee_structure_debit_card", "fee_structure_ach"].includes(changedField);
				break;
			case "dateChange":
				shouldUpdate = ["charge_transaction_fee", "fee_structure_date_change", "fee_based_on", "allow_date_changes"].includes(changedField);
				break;
			}
			if (field.feeType == "transaction" && !key.includes(value)) {
				return; // Skip this fee table if it's not related to the selected payment method
			}

			if (shouldUpdate || changedField === key) {
				let newTable;
				console.log("Updating fee table:", field.feeType);
				switch (field.feeType) {
				case "late":
					newTable = renderLateFeeTable(field, formDataStore);
					break;
				case "transaction":
					newTable = renderTransactionFeeTable(field, formDataStore);
					break;
				case "dateChange":
					newTable = renderDateChangeFeeTable(field, formDataStore);
					break;
				default:
					console.error("Unknown fee type");
					return;
				}
				feeTable.replaceWith(newTable);
			}
		} else {
			console.error(`Fee table not found for field "${key}"`, field, changedField, value);
		}
	});
}